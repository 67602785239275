import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from '@react-spring/web';
import { Box, Button, Typography, Link } from '@mui/material';

interface ExtromonAttributes {
  [key: string]: number | string;
}

interface ExtromonData {
    name: string;
  title: string;
  description: string;
  image: string;
  collectionName: string;
  series: string;
  rarity: string;
  element: string;
  personality: string;
  alignment: string;
  attributes: ExtromonAttributes;
  website: string;
  externalUrl: string;
  license: string;
  aiGenerated: string;
  aiModel: string;
  roleplayPrompt: string;
}

const AnimatedBox = animated(Box);

const ExtromonViewer: React.FC<{ content: ExtromonData; id?: string; goToNextContent: () => void; }> = ({ content, id, goToNextContent }) => {
    const [ref, inView] = useInView({ triggerOnce: true });
    const springProps = useSpring({
      to: { opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(220px)' },
      from: { opacity: 0, transform: 'translateY(220px)' },
      config: { duration: 900, tension: 290, friction: 40 },
    });
  
    const handleClick = () => {
      goToNextContent(); // This can be used to navigate through different Extromons
    };
  
    return (
      <AnimatedBox
        ref={ref}
        id={id}
        sx={{
            
          backgroundImage: `url(${content.image.startsWith('http') ? content.image : `https://sage-towers-one.quicknode-ipfs.com/ipfs/${content.image}`})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'row', // Use row for larger screens
          alignItems: 'center',
          justifyContent: 'space-evenly', // Evenly space child elements
          boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
          margin: 'auto',
          padding: "0.5rem",
          borderRadius: "3px",
          minHeight: '60vh',
        }}
        style={springProps}
      >
        {/* Name Section */}
        <Box
  sx={{
    bgcolor: "rgba(23, 23, 33, 0.89)",
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' }, // Column layout for xs (extra-small to small) screens, row layout for md (medium) and above
    alignItems: 'center',
    textAlign: "center" || 'inherit',
    gap: 2,
    maxWidth: '100%',
    margin: '0 auto',
    padding: "0.5rem",
    borderRadius: "1px",
    boxShadow: "0px 0px 25px rgba(65, 179, 163, 0.9)",
  }}
  >
       <Box sx={{ flex: 1,  textAlign:"center", alignContent:"center"}}>
       <Typography variant="h1">EXTROMON</Typography>
       <Typography variant="body1">Where AI meets fantasy, Extromons are not just coded entities; they're your next best AI-friend. Powered by the latest in LLM, these companions grow with every word.</Typography>
        <Button onClick={handleClick} variant="contained" color="primary" 
        size="large"
        sx={{
          mb:"1rem" ,
          mt: "1rem",
          textTransform: 'none',
          fontWeight: 'bold',
        }}>
            Next Extromon
          </Button>
  </Box>
        {/* Centered Image */}
       

<Box sx={{
    flex: 1, // Allows the box to grow and take up equal space
    display: 'flex',
    justifyContent: 'center', // Center the image horizontally
    alignItems: 'center', // Center the image vertically
  }}>
    <img
      src={content.image.startsWith('http') ? content.image : `https://sage-towers-one.quicknode-ipfs.com/ipfs/${content.image}`}
      alt={content.title}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
      }}
    />
  </Box>
  
        {/* Data Section */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <Typography variant="h1" >
          {content.name}
        </Typography>
       
          <Box sx={{
  maxWidth: '400px',
  maxHeight: '200px', // Adjust this value based on your layout needs
  overflowY: 'auto', // Enable vertical scrolling 80e27e
  padding: '0.5rem', 
  textAlign:"left",  
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(128, 226, 126, 0.85)',
      outline: '1px solid  rgba(65, 179, 163, 0.9)'
    }
  }}>

   <Typography variant="body1">{content.description}</Typography>
   <Typography variant="h2">Rarity: {content.rarity}</Typography>
          <Typography variant="h2">Element: {content.element}</Typography>
          <Typography variant="h2">Personality: {content.personality}</Typography>
          <Typography variant="h2">Alignment: {content.alignment}</Typography>
  </Box>
          
        </Box>
        </Box>
      </AnimatedBox>
    );
  };
  
  export default ExtromonViewer;
  
  